import React, { useContext, createContext, useState, ReactNode, FC } from "react";
import { useNavigate } from "react-router-dom";
import  secureLocalStorage  from  "react-secure-storage";
import { sha256 } from 'js-sha256';
import {Bounce, toast} from "react-toastify";
import {
  Configuration,
  AuthenticationControllerApi,
} from '../rest-client-sdk';

interface AuthContextType {
  token: string;
  user: any;
  updateUser: (data: any) => void;
  loginAction: (data: any) => Promise<void>;
  registerAction: (data: any) => Promise<void>;
  logOut: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<any>(JSON.parse((secureLocalStorage?.getItem("user")?.toString() || '{}')));
  const [token, setToken] = useState<string>(secureLocalStorage.getItem("token")?.toString() || "");
  const navigate = useNavigate();

  const authenticationApi = new AuthenticationControllerApi(new Configuration({
    basePath: `${process.env.REACT_APP_API_URL}`
  }));

  const loginAction = async (data: any) => {
    try {
      authenticationApi.authenticate(data).then((response) => {
        let res = response.data
        if (res.statusCode === 200 && res.data) {

          let userData = {// @ts-ignore
            id: res.data.id,// @ts-ignore
            role: res.data.role// @ts-ignore
          }
          setUser(userData);

          // @ts-ignore
          setToken(res.data.token);
          // @ts-ignore
          secureLocalStorage.setItem("token", res.data.token);
          secureLocalStorage.setItem("user", JSON.stringify(userData));
          // @ts-ignore
          if (res.data.role === "ROLE_ADMIN") {
            navigate("/admin/dashboard");
            // @ts-ignore
          } else if (res.data.role === "ROLE_PHYSICIAN") {
            navigate("/physician/dashboard");
            // @ts-ignore
          } else if (res.data.role === "ROLE_PATIENT") {
            navigate("/patient/dashboard");
            // @ts-ignore
          } else if (res.data.role === "ROLE_USER") {
            navigate("/role/dashboard");
          }
        } else if (res.statusCode === 403) {
          // @ts-ignore
          toast.error(res.data.error, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      }).catch((err) => {
        console.error(err);
      });

    } catch (err) {
      console.error(err);
    }
  };

  const registerAction = async (data: any) => {
    try {
      authenticationApi.register(data).then((response) => {
        let res = response.data

        if (res.statusCode === 201 && res.data) {
          // @ts-ignore
          setToken(res.data.token);
            // @ts-ignore
          secureLocalStorage.setItem("token", res.data.token);
          navigate("/physician/dashboard");
        } else if (res.statusCode === 403) {
            // @ts-ignore
          toast.error(res.data.error, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      }).catch((err) => {
        console.error(err);
      });
    } catch (err) {
      console.error(err);
    }
  };

  const logOut = () => {
    setUser(null);
    setToken("");
    secureLocalStorage.removeItem("token");
    secureLocalStorage.removeItem("user");
    navigate("/auth/");
  };

  const updateUser = (data: any) => {
    setUser(data);
    secureLocalStorage.setItem("user", JSON.stringify(data));
  }

  return (
    <AuthContext.Provider value={{ token, user, updateUser, loginAction, registerAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export function hashPassword(password: string): string {
  return sha256(password);
}

export default AuthProvider;

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
