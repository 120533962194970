/* tslint:disable */
/* eslint-disable */
/**
 * Heuplus REST API Reference
 * Heuplus REST API Reference
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@heuplus.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AuthenticationRequest
 */
export interface AuthenticationRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface CreateAppointmentRequest
 */
export interface CreateAppointmentRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentRequest
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentRequest
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentRequest
     */
    'appointmentTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateAppointmentRequest
     */
    'duration'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentRequest
     */
    'participantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppointmentRequest
     */
    'procedureId'?: string;
}
/**
 * 
 * @export
 * @interface CreatePatientRequest
 */
export interface CreatePatientRequest {
    /**
     * 
     * @type {string}
     * @memberof CreatePatientRequest
     */
    'medications'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePatientRequest
     */
    'medicalHistory'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePatientRequest
     */
    'insuranceProviderName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePatientRequest
     */
    'insurancePolicyNumber'?: string;
}
/**
 * 
 * @export
 * @interface CreatePhysicianRequest
 */
export interface CreatePhysicianRequest {
    /**
     * 
     * @type {string}
     * @memberof CreatePhysicianRequest
     */
    'qualifications'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePhysicianRequest
     */
    'specialization'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePhysicianRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePhysicianRequest
     */
    'educationRecord'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePhysicianRequest
     */
    'previousExperience'?: string;
}
/**
 * 
 * @export
 * @interface CreateProcedureRequest
 */
export interface CreateProcedureRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateProcedureRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateProcedureRequest
     */
    'price'?: number;
}
/**
 * 
 * @export
 * @interface CreateRestrictionRequest
 */
export interface CreateRestrictionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateRestrictionRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRestrictionRequest
     */
    'startTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRestrictionRequest
     */
    'endTime'?: string;
}
/**
 * 
 * @export
 * @interface CreateSettingRequest
 */
export interface CreateSettingRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateSettingRequest
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSettingRequest
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Pageable
     */
    'sort'?: Array<string>;
}
/**
 * 
 * @export
 * @interface RegisterRequest
 */
export interface RegisterRequest {
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'gender'?: RegisterRequestGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'phoneNumber'?: string;
}

export const RegisterRequestGenderEnum = {
    Female: 'FEMALE',
    Male: 'MALE'
} as const;

export type RegisterRequestGenderEnum = typeof RegisterRequestGenderEnum[keyof typeof RegisterRequestGenderEnum];

/**
 * 
 * @export
 * @interface ResultWithDataObject
 */
export interface ResultWithDataObject {
    /**
     * 
     * @type {string}
     * @memberof ResultWithDataObject
     */
    'message'?: string;
    /**
     * 
     * @type {object}
     * @memberof ResultWithDataObject
     */
    'data'?: object;
    /**
     * 
     * @type {number}
     * @memberof ResultWithDataObject
     */
    'statusCode'?: number;
}
/**
 * 
 * @export
 * @interface UpdatePatientRequest
 */
export interface UpdatePatientRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequest
     */
    'medications'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequest
     */
    'medicalHistory'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequest
     */
    'insuranceProviderName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequest
     */
    'insurancePolicyNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequest
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequest
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequest
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequest
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePatientRequest
     */
    'profilePhotoUrl'?: string;
}
/**
 * 
 * @export
 * @interface UpdatePhysicianRequest
 */
export interface UpdatePhysicianRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePhysicianRequest
     */
    'qualifications'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePhysicianRequest
     */
    'specialization'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePhysicianRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePhysicianRequest
     */
    'educationRecord'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePhysicianRequest
     */
    'previousExperience'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePhysicianRequest
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePhysicianRequest
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePhysicianRequest
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePhysicianRequest
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePhysicianRequest
     */
    'profilePhotoUrl'?: string;
}
/**
 * 
 * @export
 * @interface UpdateProcedureRequest
 */
export interface UpdateProcedureRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateProcedureRequest
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateProcedureRequest
     */
    'price'?: number;
}
/**
 * 
 * @export
 * @interface UpdateRestrictionRequest
 */
export interface UpdateRestrictionRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateRestrictionRequest
     */
    'startTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRestrictionRequest
     */
    'endTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRestrictionRequest
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface UpdateSettingRequest
 */
export interface UpdateSettingRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateSettingRequest
     */
    'value': string;
}

/**
 * AppointmentControllerApi - axios parameter creator
 * @export
 */
export const AppointmentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creating a new appointment for the authorized patient
         * @summary Creates a new appointment
         * @param {CreateAppointmentRequest} createAppointmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create5: async (createAppointmentRequest: CreateAppointmentRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAppointmentRequest' is not null or undefined
            assertParamExists('create5', 'createAppointmentRequest', createAppointmentRequest)
            const localVarPath = `/api/v1/appointments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAppointmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Getting an appointment with it\'s details
         * @summary Getting an appointment
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        details7: async (appointmentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentId' is not null or undefined
            assertParamExists('details7', 'appointmentId', appointmentId)
            const localVarPath = `/api/v1/appointments/{appointmentId}`
                .replace(`{${"appointmentId"}}`, encodeURIComponent(String(appointmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Listing appointments of a user
         * @summary Listing appointments of a user
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list4: async (pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('list4', 'pageable', pageable)
            const localVarPath = `/api/v1/appointments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppointmentControllerApi - functional programming interface
 * @export
 */
export const AppointmentControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppointmentControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Creating a new appointment for the authorized patient
         * @summary Creates a new appointment
         * @param {CreateAppointmentRequest} createAppointmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create5(createAppointmentRequest: CreateAppointmentRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create5(createAppointmentRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppointmentControllerApi.create5']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Getting an appointment with it\'s details
         * @summary Getting an appointment
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async details7(appointmentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.details7(appointmentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppointmentControllerApi.details7']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Listing appointments of a user
         * @summary Listing appointments of a user
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async list4(pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.list4(pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AppointmentControllerApi.list4']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AppointmentControllerApi - factory interface
 * @export
 */
export const AppointmentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppointmentControllerApiFp(configuration)
    return {
        /**
         * Creating a new appointment for the authorized patient
         * @summary Creates a new appointment
         * @param {CreateAppointmentRequest} createAppointmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create5(createAppointmentRequest: CreateAppointmentRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.create5(createAppointmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Getting an appointment with it\'s details
         * @summary Getting an appointment
         * @param {string} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        details7(appointmentId: string, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.details7(appointmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Listing appointments of a user
         * @summary Listing appointments of a user
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list4(pageable: Pageable, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.list4(pageable, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppointmentControllerApi - object-oriented interface
 * @export
 * @class AppointmentControllerApi
 * @extends {BaseAPI}
 */
export class AppointmentControllerApi extends BaseAPI {
    /**
     * Creating a new appointment for the authorized patient
     * @summary Creates a new appointment
     * @param {CreateAppointmentRequest} createAppointmentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentControllerApi
     */
    public create5(createAppointmentRequest: CreateAppointmentRequest, options?: RawAxiosRequestConfig) {
        return AppointmentControllerApiFp(this.configuration).create5(createAppointmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Getting an appointment with it\'s details
     * @summary Getting an appointment
     * @param {string} appointmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentControllerApi
     */
    public details7(appointmentId: string, options?: RawAxiosRequestConfig) {
        return AppointmentControllerApiFp(this.configuration).details7(appointmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Listing appointments of a user
     * @summary Listing appointments of a user
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentControllerApi
     */
    public list4(pageable: Pageable, options?: RawAxiosRequestConfig) {
        return AppointmentControllerApiFp(this.configuration).list4(pageable, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AuthenticationControllerApi - axios parameter creator
 * @export
 */
export const AuthenticationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * By taking credentials authenticates the user
         * @summary Login handler for users
         * @param {AuthenticationRequest} authenticationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate: async (authenticationRequest: AuthenticationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authenticationRequest' is not null or undefined
            assertParamExists('authenticate', 'authenticationRequest', authenticationRequest)
            const localVarPath = `/api/v1/auth/authenticate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authenticationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upon success creates a new account with USER role
         * @summary Registration handler for users
         * @param {RegisterRequest} registerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (registerRequest: RegisterRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerRequest' is not null or undefined
            assertParamExists('register', 'registerRequest', registerRequest)
            const localVarPath = `/api/v1/auth/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationControllerApi - functional programming interface
 * @export
 */
export const AuthenticationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * By taking credentials authenticates the user
         * @summary Login handler for users
         * @param {AuthenticationRequest} authenticationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticate(authenticationRequest: AuthenticationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticate(authenticationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationControllerApi.authenticate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Upon success creates a new account with USER role
         * @summary Registration handler for users
         * @param {RegisterRequest} registerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(registerRequest: RegisterRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.register(registerRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationControllerApi.register']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthenticationControllerApi - factory interface
 * @export
 */
export const AuthenticationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationControllerApiFp(configuration)
    return {
        /**
         * By taking credentials authenticates the user
         * @summary Login handler for users
         * @param {AuthenticationRequest} authenticationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate(authenticationRequest: AuthenticationRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.authenticate(authenticationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Upon success creates a new account with USER role
         * @summary Registration handler for users
         * @param {RegisterRequest} registerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(registerRequest: RegisterRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.register(registerRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationControllerApi - object-oriented interface
 * @export
 * @class AuthenticationControllerApi
 * @extends {BaseAPI}
 */
export class AuthenticationControllerApi extends BaseAPI {
    /**
     * By taking credentials authenticates the user
     * @summary Login handler for users
     * @param {AuthenticationRequest} authenticationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationControllerApi
     */
    public authenticate(authenticationRequest: AuthenticationRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationControllerApiFp(this.configuration).authenticate(authenticationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upon success creates a new account with USER role
     * @summary Registration handler for users
     * @param {RegisterRequest} registerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationControllerApi
     */
    public register(registerRequest: RegisterRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationControllerApiFp(this.configuration).register(registerRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PatientControllerApi - axios parameter creator
 * @export
 */
export const PatientControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Converting an account\'s role from temporary USER to PATIENT with adding other details for the patient
         * @summary Converts a USER account to PATIENT
         * @param {CreatePatientRequest} createPatientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create4: async (createPatientRequest: CreatePatientRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPatientRequest' is not null or undefined
            assertParamExists('create4', 'createPatientRequest', createPatientRequest)
            const localVarPath = `/api/v1/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPatientRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Using the authentication context gets a patient\'s details
         * @summary Gets a Patient\'s details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        details6: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updating a patients details
         * @summary Update a patient
         * @param {UpdatePatientRequest} updatePatientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update3: async (updatePatientRequest: UpdatePatientRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePatientRequest' is not null or undefined
            assertParamExists('update3', 'updatePatientRequest', updatePatientRequest)
            const localVarPath = `/api/v1/patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePatientRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientControllerApi - functional programming interface
 * @export
 */
export const PatientControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Converting an account\'s role from temporary USER to PATIENT with adding other details for the patient
         * @summary Converts a USER account to PATIENT
         * @param {CreatePatientRequest} createPatientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create4(createPatientRequest: CreatePatientRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create4(createPatientRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientControllerApi.create4']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Using the authentication context gets a patient\'s details
         * @summary Gets a Patient\'s details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async details6(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.details6(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientControllerApi.details6']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updating a patients details
         * @summary Update a patient
         * @param {UpdatePatientRequest} updatePatientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update3(updatePatientRequest: UpdatePatientRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update3(updatePatientRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PatientControllerApi.update3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PatientControllerApi - factory interface
 * @export
 */
export const PatientControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientControllerApiFp(configuration)
    return {
        /**
         * Converting an account\'s role from temporary USER to PATIENT with adding other details for the patient
         * @summary Converts a USER account to PATIENT
         * @param {CreatePatientRequest} createPatientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create4(createPatientRequest: CreatePatientRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.create4(createPatientRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Using the authentication context gets a patient\'s details
         * @summary Gets a Patient\'s details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        details6(options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.details6(options).then((request) => request(axios, basePath));
        },
        /**
         * Updating a patients details
         * @summary Update a patient
         * @param {UpdatePatientRequest} updatePatientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update3(updatePatientRequest: UpdatePatientRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.update3(updatePatientRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientControllerApi - object-oriented interface
 * @export
 * @class PatientControllerApi
 * @extends {BaseAPI}
 */
export class PatientControllerApi extends BaseAPI {
    /**
     * Converting an account\'s role from temporary USER to PATIENT with adding other details for the patient
     * @summary Converts a USER account to PATIENT
     * @param {CreatePatientRequest} createPatientRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientControllerApi
     */
    public create4(createPatientRequest: CreatePatientRequest, options?: RawAxiosRequestConfig) {
        return PatientControllerApiFp(this.configuration).create4(createPatientRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Using the authentication context gets a patient\'s details
     * @summary Gets a Patient\'s details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientControllerApi
     */
    public details6(options?: RawAxiosRequestConfig) {
        return PatientControllerApiFp(this.configuration).details6(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updating a patients details
     * @summary Update a patient
     * @param {UpdatePatientRequest} updatePatientRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientControllerApi
     */
    public update3(updatePatientRequest: UpdatePatientRequest, options?: RawAxiosRequestConfig) {
        return PatientControllerApiFp(this.configuration).update3(updatePatientRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PhysicianControllerApi - axios parameter creator
 * @export
 */
export const PhysicianControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Converting an account\'s role from temporary USER to PHYSICIAN with adding other details for the physician
         * @summary Converts a USER account to PHYSICIAN
         * @param {CreatePhysicianRequest} createPhysicianRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create3: async (createPhysicianRequest: CreatePhysicianRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPhysicianRequest' is not null or undefined
            assertParamExists('create3', 'createPhysicianRequest', createPhysicianRequest)
            const localVarPath = `/api/v1/physicians`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPhysicianRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Using the authentication context gets a physician\'s details or gets the physician from ID givenas path variable
         * @summary Gets a Physician\'s details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        details4: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/physicians/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Using the authentication context gets a physician\'s details or gets the physician from ID givenas path variable
         * @summary Gets a Physician\'s details
         * @param {string} physicianId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        details5: async (physicianId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'physicianId' is not null or undefined
            assertParamExists('details5', 'physicianId', physicianId)
            const localVarPath = `/api/v1/physicians/{physicianId}`
                .replace(`{${"physicianId"}}`, encodeURIComponent(String(physicianId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a Physician\'s availability details
         * @summary Gets a Physician\'s availability details
         * @param {string} physicianId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailability: async (physicianId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'physicianId' is not null or undefined
            assertParamExists('getAvailability', 'physicianId', physicianId)
            const localVarPath = `/api/v1/physicians/{physicianId}/availability`
                .replace(`{${"physicianId"}}`, encodeURIComponent(String(physicianId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the list of physicians with pagination
         * @summary Gets a list of physicians
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list3: async (pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('list3', 'pageable', pageable)
            const localVarPath = `/api/v1/physicians`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a physicians details
         * @summary Updates a physician
         * @param {UpdatePhysicianRequest} updatePhysicianRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update2: async (updatePhysicianRequest: UpdatePhysicianRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePhysicianRequest' is not null or undefined
            assertParamExists('update2', 'updatePhysicianRequest', updatePhysicianRequest)
            const localVarPath = `/api/v1/physicians`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePhysicianRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PhysicianControllerApi - functional programming interface
 * @export
 */
export const PhysicianControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PhysicianControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Converting an account\'s role from temporary USER to PHYSICIAN with adding other details for the physician
         * @summary Converts a USER account to PHYSICIAN
         * @param {CreatePhysicianRequest} createPhysicianRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create3(createPhysicianRequest: CreatePhysicianRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create3(createPhysicianRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PhysicianControllerApi.create3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Using the authentication context gets a physician\'s details or gets the physician from ID givenas path variable
         * @summary Gets a Physician\'s details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async details4(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.details4(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PhysicianControllerApi.details4']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Using the authentication context gets a physician\'s details or gets the physician from ID givenas path variable
         * @summary Gets a Physician\'s details
         * @param {string} physicianId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async details5(physicianId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.details5(physicianId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PhysicianControllerApi.details5']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets a Physician\'s availability details
         * @summary Gets a Physician\'s availability details
         * @param {string} physicianId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailability(physicianId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailability(physicianId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PhysicianControllerApi.getAvailability']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets the list of physicians with pagination
         * @summary Gets a list of physicians
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async list3(pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.list3(pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PhysicianControllerApi.list3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates a physicians details
         * @summary Updates a physician
         * @param {UpdatePhysicianRequest} updatePhysicianRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update2(updatePhysicianRequest: UpdatePhysicianRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update2(updatePhysicianRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PhysicianControllerApi.update2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PhysicianControllerApi - factory interface
 * @export
 */
export const PhysicianControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PhysicianControllerApiFp(configuration)
    return {
        /**
         * Converting an account\'s role from temporary USER to PHYSICIAN with adding other details for the physician
         * @summary Converts a USER account to PHYSICIAN
         * @param {CreatePhysicianRequest} createPhysicianRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create3(createPhysicianRequest: CreatePhysicianRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.create3(createPhysicianRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Using the authentication context gets a physician\'s details or gets the physician from ID givenas path variable
         * @summary Gets a Physician\'s details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        details4(options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.details4(options).then((request) => request(axios, basePath));
        },
        /**
         * Using the authentication context gets a physician\'s details or gets the physician from ID givenas path variable
         * @summary Gets a Physician\'s details
         * @param {string} physicianId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        details5(physicianId: string, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.details5(physicianId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a Physician\'s availability details
         * @summary Gets a Physician\'s availability details
         * @param {string} physicianId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailability(physicianId: string, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.getAvailability(physicianId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the list of physicians with pagination
         * @summary Gets a list of physicians
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list3(pageable: Pageable, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.list3(pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a physicians details
         * @summary Updates a physician
         * @param {UpdatePhysicianRequest} updatePhysicianRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update2(updatePhysicianRequest: UpdatePhysicianRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.update2(updatePhysicianRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PhysicianControllerApi - object-oriented interface
 * @export
 * @class PhysicianControllerApi
 * @extends {BaseAPI}
 */
export class PhysicianControllerApi extends BaseAPI {
    /**
     * Converting an account\'s role from temporary USER to PHYSICIAN with adding other details for the physician
     * @summary Converts a USER account to PHYSICIAN
     * @param {CreatePhysicianRequest} createPhysicianRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhysicianControllerApi
     */
    public create3(createPhysicianRequest: CreatePhysicianRequest, options?: RawAxiosRequestConfig) {
        return PhysicianControllerApiFp(this.configuration).create3(createPhysicianRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Using the authentication context gets a physician\'s details or gets the physician from ID givenas path variable
     * @summary Gets a Physician\'s details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhysicianControllerApi
     */
    public details4(options?: RawAxiosRequestConfig) {
        return PhysicianControllerApiFp(this.configuration).details4(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Using the authentication context gets a physician\'s details or gets the physician from ID givenas path variable
     * @summary Gets a Physician\'s details
     * @param {string} physicianId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhysicianControllerApi
     */
    public details5(physicianId: string, options?: RawAxiosRequestConfig) {
        return PhysicianControllerApiFp(this.configuration).details5(physicianId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a Physician\'s availability details
     * @summary Gets a Physician\'s availability details
     * @param {string} physicianId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhysicianControllerApi
     */
    public getAvailability(physicianId: string, options?: RawAxiosRequestConfig) {
        return PhysicianControllerApiFp(this.configuration).getAvailability(physicianId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the list of physicians with pagination
     * @summary Gets a list of physicians
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhysicianControllerApi
     */
    public list3(pageable: Pageable, options?: RawAxiosRequestConfig) {
        return PhysicianControllerApiFp(this.configuration).list3(pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a physicians details
     * @summary Updates a physician
     * @param {UpdatePhysicianRequest} updatePhysicianRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhysicianControllerApi
     */
    public update2(updatePhysicianRequest: UpdatePhysicianRequest, options?: RawAxiosRequestConfig) {
        return PhysicianControllerApiFp(this.configuration).update2(updatePhysicianRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProcedureControllerApi - axios parameter creator
 * @export
 */
export const ProcedureControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creating a new procedure for the authorized physician
         * @summary Creates a new procedure
         * @param {CreateProcedureRequest} createProcedureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create2: async (createProcedureRequest: CreateProcedureRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProcedureRequest' is not null or undefined
            assertParamExists('create2', 'createProcedureRequest', createProcedureRequest)
            const localVarPath = `/api/v1/procedures`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProcedureRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Getting a procedure with it\'s details
         * @summary Getting a procedure
         * @param {string} procedureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        details3: async (procedureId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'procedureId' is not null or undefined
            assertParamExists('details3', 'procedureId', procedureId)
            const localVarPath = `/api/v1/procedures/{procedureId}`
                .replace(`{${"procedureId"}}`, encodeURIComponent(String(procedureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Listing procedures of a physician
         * @summary Listing procedures of a physician
         * @param {string} physicianId 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list2: async (physicianId: string, pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'physicianId' is not null or undefined
            assertParamExists('list2', 'physicianId', physicianId)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('list2', 'pageable', pageable)
            const localVarPath = `/api/v1/procedures`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (physicianId !== undefined) {
                localVarQueryParameter['physicianId'] = physicianId;
            }

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a procedure\'s details for the authorized physician
         * @summary Updates a procedure
         * @param {string} procedureId 
         * @param {UpdateProcedureRequest} updateProcedureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1: async (procedureId: string, updateProcedureRequest: UpdateProcedureRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'procedureId' is not null or undefined
            assertParamExists('update1', 'procedureId', procedureId)
            // verify required parameter 'updateProcedureRequest' is not null or undefined
            assertParamExists('update1', 'updateProcedureRequest', updateProcedureRequest)
            const localVarPath = `/api/v1/procedures/{procedureId}`
                .replace(`{${"procedureId"}}`, encodeURIComponent(String(procedureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProcedureRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProcedureControllerApi - functional programming interface
 * @export
 */
export const ProcedureControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProcedureControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Creating a new procedure for the authorized physician
         * @summary Creates a new procedure
         * @param {CreateProcedureRequest} createProcedureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create2(createProcedureRequest: CreateProcedureRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create2(createProcedureRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProcedureControllerApi.create2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Getting a procedure with it\'s details
         * @summary Getting a procedure
         * @param {string} procedureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async details3(procedureId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.details3(procedureId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProcedureControllerApi.details3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Listing procedures of a physician
         * @summary Listing procedures of a physician
         * @param {string} physicianId 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async list2(physicianId: string, pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.list2(physicianId, pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProcedureControllerApi.list2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a procedure\'s details for the authorized physician
         * @summary Updates a procedure
         * @param {string} procedureId 
         * @param {UpdateProcedureRequest} updateProcedureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update1(procedureId: string, updateProcedureRequest: UpdateProcedureRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update1(procedureId, updateProcedureRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProcedureControllerApi.update1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProcedureControllerApi - factory interface
 * @export
 */
export const ProcedureControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProcedureControllerApiFp(configuration)
    return {
        /**
         * Creating a new procedure for the authorized physician
         * @summary Creates a new procedure
         * @param {CreateProcedureRequest} createProcedureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create2(createProcedureRequest: CreateProcedureRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.create2(createProcedureRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Getting a procedure with it\'s details
         * @summary Getting a procedure
         * @param {string} procedureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        details3(procedureId: string, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.details3(procedureId, options).then((request) => request(axios, basePath));
        },
        /**
         * Listing procedures of a physician
         * @summary Listing procedures of a physician
         * @param {string} physicianId 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list2(physicianId: string, pageable: Pageable, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.list2(physicianId, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a procedure\'s details for the authorized physician
         * @summary Updates a procedure
         * @param {string} procedureId 
         * @param {UpdateProcedureRequest} updateProcedureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1(procedureId: string, updateProcedureRequest: UpdateProcedureRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.update1(procedureId, updateProcedureRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProcedureControllerApi - object-oriented interface
 * @export
 * @class ProcedureControllerApi
 * @extends {BaseAPI}
 */
export class ProcedureControllerApi extends BaseAPI {
    /**
     * Creating a new procedure for the authorized physician
     * @summary Creates a new procedure
     * @param {CreateProcedureRequest} createProcedureRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcedureControllerApi
     */
    public create2(createProcedureRequest: CreateProcedureRequest, options?: RawAxiosRequestConfig) {
        return ProcedureControllerApiFp(this.configuration).create2(createProcedureRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Getting a procedure with it\'s details
     * @summary Getting a procedure
     * @param {string} procedureId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcedureControllerApi
     */
    public details3(procedureId: string, options?: RawAxiosRequestConfig) {
        return ProcedureControllerApiFp(this.configuration).details3(procedureId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Listing procedures of a physician
     * @summary Listing procedures of a physician
     * @param {string} physicianId 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcedureControllerApi
     */
    public list2(physicianId: string, pageable: Pageable, options?: RawAxiosRequestConfig) {
        return ProcedureControllerApiFp(this.configuration).list2(physicianId, pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a procedure\'s details for the authorized physician
     * @summary Updates a procedure
     * @param {string} procedureId 
     * @param {UpdateProcedureRequest} updateProcedureRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcedureControllerApi
     */
    public update1(procedureId: string, updateProcedureRequest: UpdateProcedureRequest, options?: RawAxiosRequestConfig) {
        return ProcedureControllerApiFp(this.configuration).update1(procedureId, updateProcedureRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RestrictionControllerApi - axios parameter creator
 * @export
 */
export const RestrictionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete a restriction\'s details for the authorized physician
         * @summary Deletes a restriction
         * @param {string} restrictionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (restrictionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restrictionId' is not null or undefined
            assertParamExists('_delete', 'restrictionId', restrictionId)
            const localVarPath = `/api/v1/restrictions/{restrictionId}`
                .replace(`{${"restrictionId"}}`, encodeURIComponent(String(restrictionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creating a new restriction for the authorized physician
         * @summary Creates a new restriction
         * @param {CreateRestrictionRequest} createRestrictionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create1: async (createRestrictionRequest: CreateRestrictionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRestrictionRequest' is not null or undefined
            assertParamExists('create1', 'createRestrictionRequest', createRestrictionRequest)
            const localVarPath = `/api/v1/restrictions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRestrictionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Getting a restriction with it\'s details
         * @summary Getting a restriction
         * @param {string} restrictionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        details2: async (restrictionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restrictionId' is not null or undefined
            assertParamExists('details2', 'restrictionId', restrictionId)
            const localVarPath = `/api/v1/restrictions/{restrictionId}`
                .replace(`{${"restrictionId"}}`, encodeURIComponent(String(restrictionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Listing restrictions of a physician
         * @summary Listing restrictions of a physician
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list1: async (pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('list1', 'pageable', pageable)
            const localVarPath = `/api/v1/restrictions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a restriction\'s details for the authorized physician
         * @summary Updates a restriction
         * @param {string} restrictionId 
         * @param {UpdateRestrictionRequest} updateRestrictionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (restrictionId: string, updateRestrictionRequest: UpdateRestrictionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restrictionId' is not null or undefined
            assertParamExists('update', 'restrictionId', restrictionId)
            // verify required parameter 'updateRestrictionRequest' is not null or undefined
            assertParamExists('update', 'updateRestrictionRequest', updateRestrictionRequest)
            const localVarPath = `/api/v1/restrictions/{restrictionId}`
                .replace(`{${"restrictionId"}}`, encodeURIComponent(String(restrictionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRestrictionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RestrictionControllerApi - functional programming interface
 * @export
 */
export const RestrictionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RestrictionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete a restriction\'s details for the authorized physician
         * @summary Deletes a restriction
         * @param {string} restrictionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(restrictionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(restrictionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RestrictionControllerApi._delete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creating a new restriction for the authorized physician
         * @summary Creates a new restriction
         * @param {CreateRestrictionRequest} createRestrictionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create1(createRestrictionRequest: CreateRestrictionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create1(createRestrictionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RestrictionControllerApi.create1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Getting a restriction with it\'s details
         * @summary Getting a restriction
         * @param {string} restrictionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async details2(restrictionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.details2(restrictionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RestrictionControllerApi.details2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Listing restrictions of a physician
         * @summary Listing restrictions of a physician
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async list1(pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.list1(pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RestrictionControllerApi.list1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a restriction\'s details for the authorized physician
         * @summary Updates a restriction
         * @param {string} restrictionId 
         * @param {UpdateRestrictionRequest} updateRestrictionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(restrictionId: string, updateRestrictionRequest: UpdateRestrictionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(restrictionId, updateRestrictionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RestrictionControllerApi.update']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RestrictionControllerApi - factory interface
 * @export
 */
export const RestrictionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RestrictionControllerApiFp(configuration)
    return {
        /**
         * Delete a restriction\'s details for the authorized physician
         * @summary Deletes a restriction
         * @param {string} restrictionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(restrictionId: string, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp._delete(restrictionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Creating a new restriction for the authorized physician
         * @summary Creates a new restriction
         * @param {CreateRestrictionRequest} createRestrictionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create1(createRestrictionRequest: CreateRestrictionRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.create1(createRestrictionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Getting a restriction with it\'s details
         * @summary Getting a restriction
         * @param {string} restrictionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        details2(restrictionId: string, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.details2(restrictionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Listing restrictions of a physician
         * @summary Listing restrictions of a physician
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list1(pageable: Pageable, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.list1(pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a restriction\'s details for the authorized physician
         * @summary Updates a restriction
         * @param {string} restrictionId 
         * @param {UpdateRestrictionRequest} updateRestrictionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(restrictionId: string, updateRestrictionRequest: UpdateRestrictionRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.update(restrictionId, updateRestrictionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RestrictionControllerApi - object-oriented interface
 * @export
 * @class RestrictionControllerApi
 * @extends {BaseAPI}
 */
export class RestrictionControllerApi extends BaseAPI {
    /**
     * Delete a restriction\'s details for the authorized physician
     * @summary Deletes a restriction
     * @param {string} restrictionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestrictionControllerApi
     */
    public _delete(restrictionId: string, options?: RawAxiosRequestConfig) {
        return RestrictionControllerApiFp(this.configuration)._delete(restrictionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creating a new restriction for the authorized physician
     * @summary Creates a new restriction
     * @param {CreateRestrictionRequest} createRestrictionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestrictionControllerApi
     */
    public create1(createRestrictionRequest: CreateRestrictionRequest, options?: RawAxiosRequestConfig) {
        return RestrictionControllerApiFp(this.configuration).create1(createRestrictionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Getting a restriction with it\'s details
     * @summary Getting a restriction
     * @param {string} restrictionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestrictionControllerApi
     */
    public details2(restrictionId: string, options?: RawAxiosRequestConfig) {
        return RestrictionControllerApiFp(this.configuration).details2(restrictionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Listing restrictions of a physician
     * @summary Listing restrictions of a physician
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestrictionControllerApi
     */
    public list1(pageable: Pageable, options?: RawAxiosRequestConfig) {
        return RestrictionControllerApiFp(this.configuration).list1(pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a restriction\'s details for the authorized physician
     * @summary Updates a restriction
     * @param {string} restrictionId 
     * @param {UpdateRestrictionRequest} updateRestrictionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestrictionControllerApi
     */
    public update(restrictionId: string, updateRestrictionRequest: UpdateRestrictionRequest, options?: RawAxiosRequestConfig) {
        return RestrictionControllerApiFp(this.configuration).update(restrictionId, updateRestrictionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SettingControllerApi - axios parameter creator
 * @export
 */
export const SettingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creating a new setting for the authorized physician or patient
         * @summary Creates a new setting
         * @param {CreateSettingRequest} createSettingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createSettingRequest: CreateSettingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSettingRequest' is not null or undefined
            assertParamExists('create', 'createSettingRequest', createSettingRequest)
            const localVarPath = `/api/v1/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSettingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Getting a setting with it\'s details
         * @summary Getting a setting
         * @param {string} settingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        details1: async (settingId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingId' is not null or undefined
            assertParamExists('details1', 'settingId', settingId)
            const localVarPath = `/api/v1/settings/{settingId}`
                .replace(`{${"settingId"}}`, encodeURIComponent(String(settingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Getting a list of settings with it\'s details
         * @summary Getting a list of settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updating a setting for the authorized physician or patient
         * @summary Updates a setting
         * @param {string} settingId 
         * @param {UpdateSettingRequest} updateSettingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update4: async (settingId: string, updateSettingRequest: UpdateSettingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingId' is not null or undefined
            assertParamExists('update4', 'settingId', settingId)
            // verify required parameter 'updateSettingRequest' is not null or undefined
            assertParamExists('update4', 'updateSettingRequest', updateSettingRequest)
            const localVarPath = `/api/v1/settings/{settingId}`
                .replace(`{${"settingId"}}`, encodeURIComponent(String(settingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSettingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingControllerApi - functional programming interface
 * @export
 */
export const SettingControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Creating a new setting for the authorized physician or patient
         * @summary Creates a new setting
         * @param {CreateSettingRequest} createSettingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createSettingRequest: CreateSettingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createSettingRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingControllerApi.create']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Getting a setting with it\'s details
         * @summary Getting a setting
         * @param {string} settingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async details1(settingId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.details1(settingId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingControllerApi.details1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Getting a list of settings with it\'s details
         * @summary Getting a list of settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async list(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.list(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingControllerApi.list']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updating a setting for the authorized physician or patient
         * @summary Updates a setting
         * @param {string} settingId 
         * @param {UpdateSettingRequest} updateSettingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update4(settingId: string, updateSettingRequest: UpdateSettingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update4(settingId, updateSettingRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingControllerApi.update4']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SettingControllerApi - factory interface
 * @export
 */
export const SettingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingControllerApiFp(configuration)
    return {
        /**
         * Creating a new setting for the authorized physician or patient
         * @summary Creates a new setting
         * @param {CreateSettingRequest} createSettingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createSettingRequest: CreateSettingRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.create(createSettingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Getting a setting with it\'s details
         * @summary Getting a setting
         * @param {string} settingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        details1(settingId: string, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.details1(settingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Getting a list of settings with it\'s details
         * @summary Getting a list of settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.list(options).then((request) => request(axios, basePath));
        },
        /**
         * Updating a setting for the authorized physician or patient
         * @summary Updates a setting
         * @param {string} settingId 
         * @param {UpdateSettingRequest} updateSettingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update4(settingId: string, updateSettingRequest: UpdateSettingRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.update4(settingId, updateSettingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingControllerApi - object-oriented interface
 * @export
 * @class SettingControllerApi
 * @extends {BaseAPI}
 */
export class SettingControllerApi extends BaseAPI {
    /**
     * Creating a new setting for the authorized physician or patient
     * @summary Creates a new setting
     * @param {CreateSettingRequest} createSettingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingControllerApi
     */
    public create(createSettingRequest: CreateSettingRequest, options?: RawAxiosRequestConfig) {
        return SettingControllerApiFp(this.configuration).create(createSettingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Getting a setting with it\'s details
     * @summary Getting a setting
     * @param {string} settingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingControllerApi
     */
    public details1(settingId: string, options?: RawAxiosRequestConfig) {
        return SettingControllerApiFp(this.configuration).details1(settingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Getting a list of settings with it\'s details
     * @summary Getting a list of settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingControllerApi
     */
    public list(options?: RawAxiosRequestConfig) {
        return SettingControllerApiFp(this.configuration).list(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updating a setting for the authorized physician or patient
     * @summary Updates a setting
     * @param {string} settingId 
     * @param {UpdateSettingRequest} updateSettingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingControllerApi
     */
    public update4(settingId: string, updateSettingRequest: UpdateSettingRequest, options?: RawAxiosRequestConfig) {
        return SettingControllerApiFp(this.configuration).update4(settingId, updateSettingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ShortUrlControllerApi - axios parameter creator
 * @export
 */
export const ShortUrlControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Getting a short url with it\'s details
         * @summary Getting a shortUrl
         * @param {string} shortUrlKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        details: async (shortUrlKey: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shortUrlKey' is not null or undefined
            assertParamExists('details', 'shortUrlKey', shortUrlKey)
            const localVarPath = `/api/v1/shortUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (shortUrlKey !== undefined) {
                localVarQueryParameter['shortUrlKey'] = shortUrlKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShortUrlControllerApi - functional programming interface
 * @export
 */
export const ShortUrlControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShortUrlControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Getting a short url with it\'s details
         * @summary Getting a shortUrl
         * @param {string} shortUrlKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async details(shortUrlKey: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultWithDataObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.details(shortUrlKey, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShortUrlControllerApi.details']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ShortUrlControllerApi - factory interface
 * @export
 */
export const ShortUrlControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShortUrlControllerApiFp(configuration)
    return {
        /**
         * Getting a short url with it\'s details
         * @summary Getting a shortUrl
         * @param {string} shortUrlKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        details(shortUrlKey: string, options?: RawAxiosRequestConfig): AxiosPromise<ResultWithDataObject> {
            return localVarFp.details(shortUrlKey, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShortUrlControllerApi - object-oriented interface
 * @export
 * @class ShortUrlControllerApi
 * @extends {BaseAPI}
 */
export class ShortUrlControllerApi extends BaseAPI {
    /**
     * Getting a short url with it\'s details
     * @summary Getting a shortUrl
     * @param {string} shortUrlKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortUrlControllerApi
     */
    public details(shortUrlKey: string, options?: RawAxiosRequestConfig) {
        return ShortUrlControllerApiFp(this.configuration).details(shortUrlKey, options).then((request) => request(this.axios, this.basePath));
    }
}



